/* eslint-disable */
import { superfish } from 'superfish'
//=include ../../node_modules/superfish/dist/js/superfish.min.js
//=include ../../node_modules/simplebar/dist/simplebar.js
//=include ../../node_modules/js-cookie/src/js.cookie.js
/* eslint-enable */

// (function() {
//
// 	$(document).ready(function() {
// 		window.setTimeout(function() {
// 			if (Cookies.get('giving-tuesday-dismissed') !== 'true') {
// 				$('[data-remodal-id=giving-tuesday]').remodal().open();
// 			}
// 		}, 10000);
//
// 		$(document).on('closed', '.remodal', function() {
// 			Cookies.set('giving-tuesday-dismissed', 'true');
// 		});
//
// 		$('#giving_tuesday').on('click', function() {
// 			Cookies.set('giving-tuesday-dismissed', 'true');
// 		});
// 	});
//
// })(jQuery);

jQuery(document).ready(function($) {
	$('#menu').superfish({
		delay: 500,
		speed: 100,
	})
})

// Fixes loading icon for MemberPress
// The MemberPress plugin did not have a hook to access this part of the plugin
// And the issue was with the loading image coming from the admin section and
// Sucuri blocking the image.
jQuery('.mepr-loading-gif').attr(
	'src',
	'/wp-content/themes/wp-foundation-six-build-child/assets/images/loading.gif'
)
